import Cards from "../LongText_Card";

function OurVision_AboutUs() {
  return (
    <section className="xs:w-[80%] md:w-[90%] lg:w-[90%] xl:w-[70%] xxl:w-[60%] flex flex-col justify-center gap-24">
      <section className="text-center flex flex-col justify-center items-center gap-6">
        <div className="xs:text-3xl lg:text-5xl font-medium">Our Vision</div>
        {/* <div className="border-b-2 border-purple w-[200px] mx-auto mt-4 mb-8"></div> */}

        <div className=" w-[90%] text-center">
          Our vision at Irvine is simple: reduce consumption of resources,
          water, electricity, etc. with a special focus in untreated - or
          inadequately treated - wastewater and minimise the amount of
          freshwater, energy and chemicals used in conventional water and
          wastewater treatment.
        </div>
      </section>
      <Cards />
    </section>
  );
}

export default OurVision_AboutUs;
