import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Importcomponents
import HomePage from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import CaseStudies from "./pages/CaseStudies";
import OurTeam from "./pages/OurTeam";
import ProductsAndServices from "./pages/ProductsAndServices";
import ContactUs from "./pages/ContactUs";
function App() {
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route
            path="/ProductsAndServices"
            element={<ProductsAndServices />}
          />
          <Route path="/CaseStudies" element={<CaseStudies />} />

          <Route path="/OurTeam" element={<OurTeam />} />
          <Route path="/ContactUs" element={<ContactUs />} />

          {/* Define more routes here */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

// import React from "react";
// import { HashRouter, Routes, Route } from "react-router-dom";

// // Import components
// import HomePage from "./pages/HomePage";
// import AboutUs from "./pages/AboutUs";
// import OurTeam from "./pages/OurTeam";
// import ProductsAndServices from "./pages/ProductsAndServices";

// function App() {
//   return (
//     <div className="overflow-x-hidden overflow-y-hidden">
//       <HashRouter basename="/">
//         <Routes>
//           <Route path="/" element={<HomePage />} />
//           <Route path="/AboutUs" element={<AboutUs />} />
//           <Route
//             path="/ProductsAndServices"
//             element={<ProductsAndServices />}
//           />
//           <Route path="/OurTeam" element={<OurTeam />} />
//           {/* Define more routes here */}
//         </Routes>
//       </HashRouter>
//     </div>
//   );
// }

// export default App;
