import DEVICES from "../../assets/devices_toolbox.svg";
import TOOLBOX_CARDS from "../ToolBox_Cards";

function OurToolBox() {
  return (
    <section className="flex flex-col justify-center items-center xs:w-[80%] md:w-[90%] lg:w-[90%] xl:w-[70%] xxl:w-[60%]">
      <div className="xs:text-4xl lg:text-5xl font-medium mb-5">Our Tool Box</div>
      {/* <div className="border-b-2 border-purple w-[200px] mx-auto mt-4 mb-8"></div> */}

      <div className="  text-center mb-6 ">
        Irvine will model your system, quickly and effectively. It makes the
        life of busy Operators and Managers easier.
      </div>
      <img src={DEVICES} alt="Devices" className="xs:w-full md:w-4/5 " />
      <TOOLBOX_CARDS />
    </section>
  );
}

export default OurToolBox;
