function Hero_CaseStudies() {
  return (
    <section className="bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center h-[50vh] w-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-5">
        <div className="xs:text-5xl lg:text-6xl text-white font-medium text-center">
          Case Studies
        </div>
        {/* <div className="xs:text-base lg:text-lg text-white text-center w-[80%]">
          Bringing Data Analytics Industry, Creating a True, Real-time Digital
          Twin for Operational Efficiency and Cost Savings
        </div> */}
      </div>
    </section>
  );
}

export default Hero_CaseStudies;
