import React from "react";

import NavBar from "../components/NavBar";
import Hero from "../components/homepage/Hero";
import OurSystem from "../components/homepage/OurSystem";
import AboutUs from "../components/homepage/AboutUs";
import RealTimeAnalytics from "../components/homepage/RealTimeAnalytics";
import HowCanIrvineHelp from "../components/homepage/HowCanIrvineHelp";
import FoodBeverageManufacturing from "../components/homepage/FoodBeverageManufacturing";
import GetInTouchWithUs from "../components/GetInTouchWithUs";
import Footer from "../components/Footer";
import SaasSolution from "../components/homepage/SaasSolution";
import useScrollToTop from "../hooks/useScrollToTop";

function HomePage() {
  useScrollToTop();
  return (
    <section className="flex flex-col ">
      <NavBar />
      <div className="h-20"></div>
      <div className="flex flex-col gap-24">
        <Hero />
        <OurSystem />
        <SaasSolution />
        <AboutUs />
        <RealTimeAnalytics />
        <HowCanIrvineHelp />
        <FoodBeverageManufacturing />
        <GetInTouchWithUs />
      </div>
      <Footer />
    </section>
  );
}

export default HomePage;

// <div className="">
//   <NavBar />
//   <Hero />
//   <div className="xs:h-20 h-0"></div>
//   <OurSystem />
//   <div className="h-20"></div>
//   <AboutUs />
//   <div className="h-44 bg-gray"></div>
//   <RealTimeAnalytics />
//   <div className="h-32"></div>
//   <HowCanIrvineHelp />
//   <div className="h-32"></div>
//   <FoodBeverageManufacturing />
//   <div className="mt-32 bg-gradient-to-r from-gray xs:from-[50%] lg:from-[65%] to-white xs:to-[50%] lg:to-[35%]">
//     <GetInTouchWithUs />
//   </div>
//   <Footer />
// </div>
