import FORM_CARD from "./GetInTouchWithUs_formCard";

function GetInTouchWithUs() {
  return (
    <section className="w-full flex xs:flex-col lg:flex-row justify-center xs:items-center lg:items-start xs:gap-16 md:gap-24 lg:gap-20 xl:gap-32 py-20 bg-gradient-to-tl from-lightBlue to-purple">
      <div className="flex flex-col justify-start xs:gap-5 lg:gap-5 text-white">
        <div className="xs:text-base lg:text-xl">
          READY TO EXPERIENCE EFFICIENCY?
        </div>
        <div className="text-7xl font-medium">
          <div>Get in</div>
          <div className="">Touch</div>
          <div>with Us</div>
        </div>
        <div className="xs:text-base lg:text-xl">
          <div>Stop wasting time & money</div>
          <div>Optimize your plant with Irvine. </div>
        </div>
      </div>
      <FORM_CARD />
    </section>
  );
}

export default GetInTouchWithUs;
