import IAS_LOGO from "../assets/ias_logo_gray.svg";
import FACEBOOK_LOGO from "../assets/facebook_logo_white.svg";
import TWITTER_LOGO from "../assets/twitter_logo_white.svg";
import LINKEDIN_LOGO from "../assets/linkedIn_logo_white.svg";
import EMAIL_LOGO from "../assets/email_logo_white.svg";
import { Link } from "react-router-dom";

function Footer() {
  const navItems = [
    { name: "About", link: "/AboutUs" },
    { name: "Tool Box and Services", link: "/ProductsAndServices" },
    { name: "Case Studies", link: "/CaseStudies" },
    { name: "Our Team", link: "/OurTeam" },
    { name: "Contact Us", link: "/ContactUs" },
    { name: "Terms & Conditions ", link: "#" },
    { name: "Privacy Policy", link: "#" },
  ];
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#2a31c3] flex xs:flex-col md:flex-row justify-around py-10 xs:gap-12 md:gap-16 lg:gap-16 xl:gap-32 xs:px-12 md:px-14 lg:px-24">
      <div>
        <div className="mb-7">
          <img
            src={IAS_LOGO}
            alt="IAS Logo"
            className="xs:w-3/6  sm:w-2/6 md:w-4/6 lg:w-3/5 xl:w-1/2"
          />
          <div className="text-white xs:text-sm lg:text-base">
            Optimize. Automate. Simplify.
          </div>
        </div>
        <div className="flex-col">
          <div className="flex mb-2 ">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={FACEBOOK_LOGO} alt="Facebook Logo" className="w-2/3" />
            </a>
            <a
              href="https://twitter.com/irvineas"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TWITTER_LOGO} alt="Twitter Logo" className="w-2/3" />
            </a>
            <a
              href="https://www.linkedin.com/company/irvine-analytics-solutions-inc/mycompany/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LINKEDIN_LOGO} alt="LinkedIn Logo" className="w-2/3" />
            </a>
            <a href="mailto:enquiries@irvineas.com">
              <img src={EMAIL_LOGO} alt="Email Logo" className="w-2/3" />
            </a>
          </div>
          <div className="text-white text-xs">
            Copyright © {currentYear}. All rights reserved.
          </div>
        </div>
      </div>

      <div className="md:w-1/2 xmd:w-1/3 lg:w-1/4 xl:w-1/5">
        <div className="text-white mb-5 text-xl font-semibold mt-3">
          QUICK LINKS
        </div>
        <div className="text-white flex flex-col xs:text-sm lg:text-base">
          {navItems.map((item, index) => (
            <Link key={index} to={item.link}>
              <button  className="hover:font-medium">{item.name}</button>
            </Link>
          ))}
        </div>
      </div>

      <div className="xs:2/3 md:w-1/3 mt-3 xs:text-sm lg:text-base">
        <div className="text-white text-xl font-semibold mb-5">CONTACT</div>
        <div className="text-white mb-3 flex flex-col font-semibold">
          <p className="font-bold"> SINGAPORE MAIN OFFICE</p>
          <p className="">Irvine Environmental PTE LTD</p>
          <p>68 Circular Road, #02-01, Singapore 049422</p>
        </div>
        <div className="text-white flex flex-col">
          <div className="flex flex-row gap-4">
            <div className="text-white mb-3 flex flex-col font-semibold">
              <p className="font-bold"> DUBAI </p>
              <p className="">P.O. Box 239131, Dubai ,UAE</p>
              <p>rishad@irvineas.com</p>
              <p>+971 50 8557810</p>
            </div>
            <div className="text-white mb-3 flex flex-col font-semibold">
              <p className="font-bold"> UNITED KINGDOM</p>
              <p className="">chris@irvineas.com</p>
              <p>+44 7713 860991 / +44 7397982982 </p>
            </div>
          </div>
          <div className="flex flex-row">
          <div className="text-white mb-3 flex flex-col font-semibold">
              <p className="font-bold"> PHILIPPINES</p>
              <p className="">Irvine Analytics Solutions Inc.</p>
              <p>Unit 214-215, 2/f Commercenter Alabang, Commerce Avenue, Filinvest, Muntinlupa, Metro Manila, Philippines</p>
              <p>enquiries@irvineas.com </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
