import OURSTORY_IMAGE from "../assets/ourStory.svg";
import SIMULATIONANALYTICS_IMAGE from "../assets/simulationAnalytics.svg";

function longText_Card() {
  return (
    <section className="self-center ">
      <div className="flex flex-col justify-center items-center gap-24">
        <div className="relative flex">
          <div className="flex flex-col xs:p-10 lg:p-16 md:w-3/5 lg:w-1/2 z-10 gap-5 self-end text-pretty rounded-lg xs:mt-72 md:mt-36 bg-white shadow-2xl xs:text-center md:text-left">
            <h1 className="xs:text-center md:text-start xs:text-2xl md:text-4xl font-medium">
              Our Story
            </h1>
            <p className="mt-3 xs:text-sm md:text-base text-left">
              <span className="font-semibold">
                Our vision at Irvine is simple: reduce consumption of resources
              </span>
              , water, electricity, etc. with a special focus in untreated - or
              inadequately treated - wastewater and minimise the amount of
              freshwater, energy and chemicals used in conventional water and
              wastewater treatment.
            </p>
            <p className="xs:text-sm md:text-base text-left">
              The founders have 30+ years of combined experience in this sector,
              and have utilized that experience to develop a solution which
              provides an easy to set up, powerful monitoring and reporting
              system.
            </p>
          </div>
          <img
            src={OURSTORY_IMAGE}
            alt="Our Story"
            className=" absolute md:right-0 md:w-[70%] xmd:w-[55%] xl:w-[55%] rounded-lg"
          />
        </div>

        <div className="relative flex flex-row-reverse">
          <div className="flex flex-col xs:p-10 lg:p-16 md:w-3/5 lg:w-1/2 z-10 gap-5 self-end text-pretty rounded-lg  xs:mt-72 md:mt-32 bg-white shadow-2xl xs:text-center md:text-left">
            <h1 className="md:text-start xs:text-2xl md:text-4xl font-medium">
              Simulation and Analytics
            </h1>
            <p className="mt-3 xs:text-sm md:text-base text-left">
              Irvine Operator™ strengthens the work of internal engineers and
              operators to optimize utilities, water and wastewater treatment
              performance and cost efficiency. Irvine utilizes 21st century
              technologies in an integrated environment for utilities, water and
              wastewater treatment, water reuse/recycling, cost, savings, and
              energy recovery and management.
            </p>
            <p className="xs:text-sm md:text-base text-left">
              Irvine Operator™ not just collects from the SCADA system, but
              turns data into actionable sets of numbers to assist users
              aggregate and interpret.
            </p>
          </div>
          <img
            src={SIMULATIONANALYTICS_IMAGE}
            alt="Our Story"
            className=" absolute md:left-0 md:w-[70%] xmd:w-[55%] xl:w-[55%] rounded-lg"
          />
        </div>
      </div>
    </section>
  );
}

export default longText_Card;

// <section className="flex flex-col gap-40 ">
//   <div className="bg-purple text-white flex xs:flex-col-reverse md:flex-row-reverse items-center rounded-3xl">
//     <img
//       src={OURSTORY_IMAGE}
//       alt="Our Story"
//       className="md:w-[50%] lg:w-[50%] xl:w-[45%] xxl:w-[40%] xs:rounded-b-3xl md:rounded-3xl md:mt-24 md:-mb-16 lg:-mt-16 lg:-mb-16 z-10 "
//     />
//     <div className="lg:w-[50%] xl:w-[55%] p-10 flex flex-col gap-4 xs:mb-14 md:mb-0 xs:text-center md:text-left">
//       <h2 className="xs:text-4xl lg:text-5xl font-bold mb-1">Our Story</h2>
//       <p className="xs:text-sm lg:text-base">
//         Irvine Analytics Solutions was established by experienced Water
//         Industry professionals and Project professionals to provide a
//         solution to model and optimise the performance of industrial water
//         and waste water treatment plant.
//       </p>
//       <p className="xs:text-sm lg:text-base">
//         The founders have 30+ years of combined experience in this sector,
//         and have utilized that experience to develop a solution which
//         provides an easy to set up, powerful monitoring and reporting
//         system.
//       </p>
//     </div>
//   </div>

//   <div className="bg-purple text-white flex xs:flex-col-reverse md:flex-row items-center rounded-3xl">
//     <img
//       src={SIMULATIONANALYTICS_IMAGE}
//       alt="Our Story"
//       className="md:w-[50%] lg:w-[50%] xl:w-[45%] xxl:w-[40%] xs:rounded-b-3xl md:rounded-3xl md:mb-24 md:-mt-16 lg:-mt-16 lg:-mb-16 xxl:-mt-24 xxl:-mb-24 z-10 "
//     />
//     <div className="lg:w-[50%] xl:w-[55%] xs:p-10 md:p-6 lg:p-6 xl:p-10 flex flex-col gap-4  xs:mb-14 md:mb-0 xs:text-center md:text-left">
//       <h2 className="xs:text-3xl lg:text-4xl font-bold mb-1">
//         Simulation and Analytics
//       </h2>
//       <p className="xs:text-sm lg:text-base">
//         Irvine Operator™ strengthens the work of internal engineers and
//         operators to optimize utilities, water and wastewater treatment
//         performance and cost efficiency. Irvine utilizes 21st century
//         technologies in an integrated environment for utilities, water and
//         wastewater treatment, water reuse/recycling, cost, savings, and
//         energy recovery and management.
//       </p>
//       <p className="xs:text-sm lg:text-base">
//         Irvine Operator™ not just collects from the SCADA system, but turns
//         data into actionable sets of numbers to assist users aggregate and
//         interpret.
//       </p>
//     </div>
//   </div>
// </section>
