import { MapPinIcon } from "@heroicons/react/24/solid";

function WhereToFindUs() {
  return (
    <section className=" flex flex-col justify-center items-center  bg-white w-full xs:pb-40 sm:pb-40 md:pb-10">
      <h1 className="text-center xs:text-4xl lg:text-5xl font-bold  pt-5 ">
        Where to Find Us?
      </h1>
      <div className="flex flex-col xs:w-[80%] md:w-[85%] xl:w-[80%]  items-center justify-center py-20 xs:gap-60 sm:gap-60  md:gap-10 ">
        {/* <h1 className="text-center xs:text-4xl lg:text-5xl font-bold ">
        Where to Find Us?
      </h1> */}
        {/* address 1 */}
        <div className=" h-[430px] place-items-center sm:px-0 md:px-[60px] bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center text-white rounded-[50px] shadow-xl flex md:flex-row xs:flex-col justify-between w-full ">
          <div className="md:w-1/2 flex flex-col justify-start p-10 ">
            <div className="flex flex-col  sm:text-xl md:text-4xl xl:text-5xl font-bold">
              <p>IRVINE</p>
              <p>ENVIRONMENTAL</p>
              <p>PTE. LTD.</p>
              <p className="text-2xl">MAIN OFFICE</p>
            </div>
            <div className="flex flex-row gap-3 mt-3">
              <MapPinIcon className="w-12 h-12" />
              <div className="flex flex-col align-start font-semibold">
                <p className="">
                  68 Circular Road, #02-01,
                </p>
                <p className="">
                  Singapore 049422
                </p>
              </div>

            </div>
          </div>

          <div className="xs:w-full sm:w-full md:md:w-1/2 flex justify-end">
            <iframe
              title="Singapore Office Address"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.812678070124!2d103.8465673744722!3d1.2864471617774056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190bb5febd0b%3A0x3999e3ace9eb9af!2s68%20Circular%20Rd%2C%20Unit%2002-01%2C%20Singapore%20049422!5e0!3m2!1sen!2sph!4v1721374021795!5m2!1sen!2sph"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full right-0 top-0 xs:rounded-none xs:rounded-b-[30px] sm:rounded-none sm:rounded-b-[30px] md:rounded-[30px] h-[350px] "
            ></iframe>
          </div>
        </div>

        {/* address 2 */}
        <div className="h-[430px] place-items-center sm:px-0 md:px-[60px] bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center text-white rounded-[50px] shadow-xl flex md:flex-row xs:flex-col justify-between w-full  ">
          <div className="md:w-1/2 flex flex-col justify-start p-10 ">
            <div className="flex flex-col  sm:text-xl md:text-4xl xl:text-5xl font-bold">
              <p>IRVINE ANALYTICS </p>
              <p>SOLUTIONS</p>
              <p className="text-2xl">INTERNATIONAL OFFICE</p>
            </div>
            <div className="flex flex-row gap-3 mt-3">
              <MapPinIcon className="w-12 h-12" />
              <div className="flex flex-col align-start  font-semibold">
                <p className="">
                  Unit 214-215, 2/f Commercenter
                </p>
                <p className="">
                  Alabang, Commerce Avenue
                </p>
                <p className="">
                  cor. East Asia Drive, Filinvest
                </p>
                <p className="">
                  Corporate City, Muntinlupa,
                </p>
                <p className="">
                  Metro Manila, Philippines
                </p>
              </div>

            </div>
          </div>

          <div className="xs:w-full sm:w-full md:md:w-1/2 flex justify-end">
            <iframe
              title="Philippines Office Address"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&q=Commercenter%20Alabang&zoom=10&maptype=roadmap"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full right-0 top-0 xs:rounded-none xs:rounded-b-[30px] sm:rounded-none sm:rounded-b-[30px] md:rounded-[30px] h-[350px] "
            ></iframe>
          </div>
        </div>
        {/* address 3 */}
        <div className="h-[430px] place-items-center sm:px-0 md:px-[60px] bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center text-white rounded-[50px] shadow-xl flex md:flex-row xs:flex-col justify-between w-full  ">
          <div className="md:w-1/2 flex flex-col justify-start p-10 ">
            <div className="flex flex-col  sm:text-xl md:text-4xl xl:text-5xl font-bold">
              <p>IRVINE</p>
              <p>ENVIRONMENTAL</p>
              <p>PTE. LTD.</p>
              <p className="text-2xl">INTERNATIONAL OFFICE</p>
            </div>
            <div className="flex flex-row gap-3 mt-3">
              <MapPinIcon className="w-12 h-12" />
              <div className="flex flex-col align-start font-semibold">
                <p className="">
                  P.O. Box 239131, Dubai ,UAE
                </p>
                <p className="">
                  rishad@irvineas.com
                </p>
                <p className="">
                  +971 50 8557810
                </p>
              </div>

            </div>
          </div>

          <div className="xs:w-full sm:w-full md:md:w-1/2 flex justify-end">
            <iframe
              title="Dubai P.O Box"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&q=Al%20Safawi%20dubai&zoom=10&maptype=roadmap"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full right-0 top-0  xs:rounded-none xs:rounded-b-[30px] sm:rounded-none sm:rounded-b-[30px] md:rounded-[30px] h-[350px] "
            ></iframe>
          </div>
        </div>
        {/* address 4 */}
        <div className="h-[430px] place-items-center sm:px-0 md:px-[60px] bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center text-white rounded-[50px] shadow-xl flex md:flex-row xs:flex-col justify-between w-full  ">
          <div className="md:w-1/2 flex flex-col justify-start p-10 ">
            <div className="flex flex-col  sm:text-xl md:text-4xl xl:text-5xl font-bold">
              <p>IRVINE</p>
              <p>ENVIRONMENTAL</p>
              <p>PTE. LTD.</p>
              <p className="text-2xl">INTERNATIONAL OFFICE</p>
            </div>
            <div className="flex flex-row gap-3 mt-3">
              <MapPinIcon className="w-12 h-12" />
              <div className="flex flex-col align-start font-semibold">
                <p className="">
                  UNITED KINGDOM
                </p>
                <p className="">
                  chris@irvineas.com
                </p>
                <p className="">
                  +44 7713 860991 / +44 7397982982
                </p>
              </div>

            </div>
          </div>

          <div className="xs:w-full sm:w-full md:md:w-1/2 flex justify-end">
          
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhereToFindUs;
