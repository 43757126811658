import NavBar from "../components/NavBar";
import Hero from "../components/contactUs/Hero_ContactUs";
import GetInTouchWithUs from "../components/contactUs/GetInTouchWithUs_ContactUs";
import Footer from "../components/Footer";
import WhereToFindUs from "../components/contactUs/WhereToFindUs";
import useScrollToTop from "../hooks/useScrollToTop";
import SeeHowItWorks from "../components/SeeHowItWorks";

function ContactUs() {
  useScrollToTop();
  return (
    <div>
      <div className="flex flex-col gap-20 justify-center items-center ">
        <div className="flex flex-col justify-center items-center gap-20 place-items-center">
          <NavBar />
          <Hero />
        </div>
        <div className="w-full -mt-20">
          <GetInTouchWithUs />
        </div>
        <div className="flex flex-col w-full ">
        <WhereToFindUs />
        <SeeHowItWorks />
        </div>  

      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
