import SEE_HOW_IT_WORKS_BG from "../assets/seeHowItWorks_bg.gif";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

function SeeHowItWorks() {
  return (
    <section
      style={{
        backgroundImage: `url(${SEE_HOW_IT_WORKS_BG})`,
      }}
      className="w-full bg-no-repeat bg-center bg-cover h-[40vh] flex items-center justify-center"
    >
      <div className="flex flex-col items-center justify-center gap-5">
        <div className="xs:text-4xl sm:text-5xl md:text-6xl text-7xl text-white font-medium">
          See How It Works.
        </div>
        <div>
          <Link to="/ContactUs">
            <button className="bg-black font-medium text-white hover:text-green  xs:text-xl lg:text-2xl px-6 py-3 rounded-lg flex items-center justify-center ">
              Get A Demo
              <ArrowRightIcon className="ml-2 h-10 w-8 xs:h-6 font-bold" />
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default SeeHowItWorks;
