// import HERO_PRODUCTSANDSERVICES from "../../assets/product&services_bg.png";
// import { ArrowRightIcon } from "@heroicons/react/24/solid";

function Hero_AboutUs() {
  return (
    <section className="bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center h-[50vh] w-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-5">
        <div className="xs:text-5xl lg:text-6xl text-white font-medium text-center">
          Tool Box & Services
        </div>
        <div className="xs:text-base lg:text-lg text-white text-center w-[80%]">
          Irvine Operator™ System
        </div>
        {/* <button className="flex jutify-center items-center bg-lightBlack text-white p-3 px-4 hover:text-green rounded-lg xs:text-sm lg:text-base">
          See All
          <ArrowRightIcon className="h-5 w-5 ml-2" />
        </button> */}
      </div>
    </section>
  );
}

export default Hero_AboutUs;
