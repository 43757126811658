import FORM_CARD from "../GetInTouchWithUs_formCard";
import {
  PhoneIcon,
  EnvelopeIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/solid";
import LINKEDIN_ICON from "../../assets/linkedIn_ContactUs.svg";

function GetInTouchWithUs_ContactUs() {
  return (
    <section className="w-full bg-paleBlue flex xs:flex-col lg:flex-row justify-center items-center xs:gap-16 md:gap-24 lg:gap-20 xl:gap-32 py-20 ">
      <div className="flex flex-col justify-center xs:items-center lg:items-start gap-10">
        <div className="flex flex-col gap-3 xs:text-center lg:text-start">
          <div className="">READY TO EXPERIENCE EFFICIENCY?</div>
          <div className="text-6xl font-medium">
            <div>Get in</div>
            <div>Touch</div>
            <div>with Us</div>
          </div>
          <div className="">
            <h5>Stop wasting time & money.</h5>
            <h5>Optimize your plant with Irvine.</h5>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex flex-row gap-2 items-start">
            <PhoneIcon className=" w-6 font-bold text-purple" />
            <div className="flex flex-col gap-1">
              <h4 className="text-md font-semibold">PHONE NUMBERS</h4>

              <div className="flex flex-col">
                <p>+ 63 (0) 945-346-4724 (PH)</p>
                <p>+ 63 (0) 991-212-7949 (PH)</p>
                <p>+ 63 (0) 917-845-5176 (PH)</p>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-2 items-start">
            <EnvelopeIcon className=" w-6 font-bold text-purple" />
            <div className="flex flex-col gap-1">
              <h4 className="text-md font-semibold">EMAIL</h4>
              <a href="mailto:enquiries@irvineas.com">
                <p>enquiries@irvineas.com</p>
              </a>
            </div>
          </div>

          <div className="flex flex-row gap-2 items-start">
            <GlobeAltIcon className=" w-6 font-bold text-purple" />
            <div className="flex flex-col gap-1">
              <h4 className="text-md font-semibold">SOCIALS</h4>

              <a
                href="https://www.linkedin.com/company/irvine-analytics-solutions-inc/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex flex-row gap-2 ">
                  <img src={LINKEDIN_ICON} alt="LinkedIn" className="w-5 h-5" />
                  <p>LinkedIn</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <FORM_CARD />
    </section>
  );
}

export default GetInTouchWithUs_ContactUs;
