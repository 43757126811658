function ContactUs() {
  return (
    <section className="bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center h-[50vh] w-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-5">
        <div className="xs:text-5xl lg:text-6xl text-white font-medium text-center">
          Contact Us
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
