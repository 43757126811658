import NavBar from "../components/NavBar";
import Hero from "../components/ourTeam/Hero_OurTeam";
import TeamMemberCards from "../components/ourTeam/TeamMember_Cards";
import SeeHowItWorks from "../components/SeeHowItWorks";

import GetInTouchWithUs from "../components/GetInTouchWithUs";
import Footer from "../components/Footer";
import useScrollToTop from "../hooks/useScrollToTop";

function OurTeam() {
  useScrollToTop();
  return (
    <div className="">
      <div className="flex flex-col justify-center items-center gap-20">
        <NavBar />
        <div></div>
        <Hero />
        <TeamMemberCards />
        <SeeHowItWorks />
      </div>
      <div className="w-full bg-gradient-to-r from-gray xs:from-[50%] lg:from-[65%] to-white xs:to-[50%] lg:to-[35%]">
        <GetInTouchWithUs />
      </div>
      <Footer />
    </div>
  );
}

export default OurTeam;
